<script>
/**
 * Email-sent component
 */
 const d = new Date()
export default {
  data() {
    return {
      monthNames : ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"],
      date : new Date(),
      month : d.getMonth()
    };
  },
  computed: {
      paying(){
          var tmp = this.$store.getters['stats/revenue'];
          console.log(tmp);
          return parseInt(tmp.replace(/,.*|[^0-9]/g, ''), 10)/20000;
      },
      pending(){
          var tmp = this.$store.getters['stats/pendingPayment'];
          console.log(tmp);
          return parseInt(tmp.replace(/,.*|[^0-9]/g, ''), 10)/20000;
      },
      nopay(){
          var tmp = this.$store.getters['stats/totalTryout'];
          return parseInt(tmp)-(this.paying+this.pending);
      },
      seriesMonth(){
          return [this.paying, this.pending, this.nopay]
      },
      chartOptions(){
          return {
                labels: ["Paying", "Pending", "Not Pay"],
                colors: ["#34c38f", "#556ee6", "#f46a6a"],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }]
            }
      }
  },
  mounted(){
    this.$store.dispatch('stats/getJenjangReport');
  }
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Order</h4>
            <div class="row text-center">
              <div class="col-sm-4">
                <h5 class="mb-0">{{paying}}</h5>
                <p class="text-muted">Paying</p>
              </div>
              <div class="col-sm-4">
                <h5 class="mb-0">{{pending}}</h5>
                <p class="text-muted">Pending</p>
              </div>
              <div class="col-sm-4">
                <h5 class="mb-0">{{nopay}}</h5>
                <p class="text-muted">Not Pay</p>
              </div>
            </div>
            <apexchart
              class="apex-charts"
              height="320"
              type="pie"
              dir="ltr"
              :series="seriesMonth"
              :options="chartOptions"
            ></apexchart>
    </div>
  </div>
</template>