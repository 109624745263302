/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import Transaction from "@/components/widgets/transaction";
// import graphArea from "@/components/widgets/graphArea";
import tryoutGraph from "@/components/widgets/tryoutGraph";
import graphJenjang from "@/components/widgets/graphJenjang";
import graphBayar from "@/components/widgets/graphBayar";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Transaction,tryoutGraph,graphJenjang,graphBayar },
  data() {
    return {
      title: "Dashboard",
      statData: [
        {
          icon: "bx bx-group",
          title: "Users",
          value: ""
        },
        {
          icon: "bx bx-archive-in",
          title: "Revenue",
          value: "$35, 723"
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Average Price",
          value: "$16.2"
        },
        {
          icon: "bx bx-book-open",
          title: "Soal",
          value: "$16.2"
        }
      ],
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6
        }
      ]
    };
  },
  computed: {
      User(){
          return this.$store.getters['users/user']
      },
      totalMurid(){
          return this.$store.getters['stats/totalMurid'];
      },
      revenue(){
          return this.$store.getters['stats/revenue'];
      },
      pendingPayment(){
          return this.$store.getters['stats/pendingPayment'];
      },
      totalTryout(){
          return this.$store.getters['stats/totalTryout'];
      },
  },
  mounted(){
    this.$store.dispatch('stats/getTotalMurid');
    this.$store.dispatch('stats/getRevenue');
    this.$store.dispatch('stats/getpendingPayment');
    this.$store.dispatch('stats/gettotalTryout');
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Welcome to Try Out Siswa Online Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

        <div class="row">
          <div class="col-md-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Total User</p>
                    <h4 class="mb-0">{{totalMurid}}</h4>
                  </div>

                  <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                    <span class="avatar-title">
                      <i class="bx bx-group font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Revenue</p>
                    <h4 class="mb-0">{{revenue}}</h4>
                  </div>

                  <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                    <span class="avatar-title bg-success">
                      <i class="bx bx-money font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Pending Payment</p>
                    <h4 class="mb-0">{{pendingPayment}}</h4>
                  </div>

                  <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                    <span class="avatar-title bg-danger">
                      <i class="bx bx-money font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Tryout is Taken</p>
                    <h4 class="mb-0">{{totalTryout}}</h4>
                  </div>

                  <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                    <span class="avatar-title bg-danger">
                      <i class="bx bx-edit-alt font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
        </div>
    <div class="row">
      <!-- end col -->
      <div class="col-md-6">
        <!-- Email sent -->
          <graphJenjang />
      </div>
      <div class="col-md-6">
      <tryoutGraph/>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <!--<div class="col-xl-8">
        <graphArea />
      </div>-->
      <!-- end col -->
      <div class="col-xl-4">
      <graphBayar/>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Latest Transaction</h4>
            <!-- Transactions table -->
            <Transaction :transactions="transactions" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
