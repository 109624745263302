<script>
/**
 * Transactions component
 */

const Bayar = require('./../../api/bayar');
const _bayar = new Bayar();
export default {
  props: {
    transactions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      showModal: false,
      items : []
    };
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      _bayar.getReport({"offset":0,"limit":50})
      .then((res)=>{
        this.items = res;
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="thead-light">
        <tr>
          <th style="width: 20px;">
            <div class="custom-control custom-checkbox">
              <input id="customCheck" type="checkbox" class="custom-control-input" />
              <label class="custom-control-label" for="customCheck">&nbsp;</label>
            </div>
          </th>
          <th>Order ID</th>
          <th>User Name</th>
          <th>Date</th>
          <th>Total</th>
          <th>Payment Status</th>
          <th>Payment Method</th>
          <th>VA Number</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in items" :key="data.id">
          <td>
            <div class="custom-control custom-checkbox">
              <input :id="`customCheck${data.index}`" type="checkbox" class="custom-control-input" />
              <label class="custom-control-label" :for="`customCheck${data.index}`">&nbsp;</label>
            </div>
          </td>
          <td>
            <a href="javascript: void(0);" class="text-body font-weight-bold">{{data.id}}</a>
          </td>
          <td>{{data.murid}}</td>
          <td>{{data.tgl}}</td>
          <td>{{data.jumlah}}</td>
          <td>
            <span
              class="badge badge-pill badge-soft-success font-size-12"
              :class=" { 'badge-soft-danger': `${data.status}` === `${false}`}"
            >{{data.status?'Paid':'Pending'}}</span>
          </td>
          <td>
            Bank {{data.metode_pembayaran}}
          </td>
          <td>{{data.va_number}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end table -->
</template>