<script>
/**
 * Email-sent component
 */
 const d = new Date()
export default {
  data() {
    return {
      monthNames : ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"],
      date : new Date(),
      month : d.getMonth()
    };
  },
  computed: {
      seriesMonth(){
          return [
                    {
                        data: this.$store.getters['stats/jenjangSell'],
                    }
                ]
      },
      chartOptions(){
          return {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#0b1eff'],
                grid: {
                    borderColor: '#0b1eff',
                },
                xaxis: {
                    categories: this.$store.getters['stats/jenjangs'],
                }
            }
      }
  },
  mounted(){
    this.$store.dispatch('stats/getJenjangReport');
  }
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Tryout taken by Jenjang</h4>
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="seriesMonth"
              :options="chartOptions"
            ></apexchart>
    </div>
  </div>
</template>