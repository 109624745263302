<script>
/**
 * Email-sent component
 */
 const d = new Date()
export default {
  data() {
    return {
      monthNames : ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"],
      date : new Date(),
      month : d.getMonth()
    };
  },
  computed: {
      seriesMonth(){
          return [
                    {
                        name: "Total",
                        data: this.$store.getters['stats/tryoutTakenpermonth'],
                    }
                ]
      },
      chartOptions(){
          return {
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                }
            },
            colors: ['#34c38f'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 4, 3],
                curve: 'straight',
                dashArray: [0, 8, 5]
            },
            markers: {
                size: 0,

                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: [...Array(new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate()).keys()].map(function(e){return (e+1)+this.monthNames[0].substring(0,3);}.bind(this)),
            },
            tooltip: {
                y: [{
                    title: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },]
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        }
      }
  },
  mounted(){
    this.$store.dispatch('stats/gettryoutTakenpermonth',this.date.getMonth()+1);
  },
  methods:{
      filter(month){
          this.month = month-1;
            this.$store.dispatch('stats/gettryoutTakenpermonth',month);
      }
  }
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right">
        <b-dropdown right variant="black" toggle-class="header-item">
            <template v-slot:button-content>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="javascript: void(0);">{{monthNames[month]}} <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i></a>
                    </li>
                </ul>
            </template>
            <!-- item-->
            <b-dropdown-item-button  v-for="(month,index) in monthNames" :key="index" @click="filter(index+1)" aria-describedby="dropdown-header-1">{{month}}</b-dropdown-item-button>
        </b-dropdown>
      </div>
      <h4 class="card-title mb-4">Tryout taken on this month</h4>
            <apexchart
              class="apex-charts"
              height="350"
              type="line"
              dir="ltr"
              :series="seriesMonth"
              :options="chartOptions"
            ></apexchart>
    </div>
  </div>
</template>